[data-v-1e488bfb]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
.rightPanel-background[data-v-1e488bfb] {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.rightPanel[data-v-1e488bfb] {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 40000;
  width: 100%;
  max-width: 260px;
  height: 100vh;
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
  transform: translate(100%);
}
.show[data-v-1e488bfb] {
  transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.show .rightPanel-background[data-v-1e488bfb] {
    z-index: 20000;
    width: 100%;
    height: 100%;
    opacity: 1;
}
.show .rightPanel[data-v-1e488bfb] {
    transform: translate(0);
}
.handle-button[data-v-1e488bfb] {
  position: absolute;
  left: -48px;
  z-index: 0;
  width: 48px;
  height: 48px;
  border-radius: 6px 0 0 6px !important;
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 48px;
  pointer-events: auto;
  cursor: pointer;
}
.handle-button i[data-v-1e488bfb] {
    font-size: 24px;
    line-height: 48px;
}
