[data-v-79015c01]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
[data-v-79015c01] .el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-scrollbar-bg[data-v-79015c01] {
  position: relative;
  z-index: 3;
}
.sidebar[data-v-79015c01] {
  height: calc(100% - 100px);
}
.child-menu[data-v-79015c01] {
  position: fixed;
  left: 140px;
  top: 0;
  width: 200px;
  height: 100%;
  background: #fff;
  box-shadow: 3px 0 4px rgba(0, 21, 41, 0.08);
  transition: all 0.28s;
}
.child-menu-top[data-v-79015c01] {
  z-index: 10;
}
.main-menu-line[data-v-79015c01] {
  display: block;
  width: 77px;
  height: 1px;
  margin: 20px auto;
  background-color: #99b1fe;
  opacity: 0.3;
}
.hide[data-v-79015c01] {
  left: -200px;
  overflow: hidden;
}
.narrow[data-v-79015c01] {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}
.expand[data-v-79015c01] {
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
  cursor: pointer;
}
.main-menu[data-v-79015c01] {
  padding: 8px 0 10px;
}
.theme-light .child-menu[data-v-79015c01] {
  background-color: #f5f7fb;
}
