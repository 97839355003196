[data-v-3c0cad79]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
.app-name[data-v-3c0cad79] {
  position: relative;
  display: flex;
  z-index: 1;
  height: 30px;
  margin-top: -13px;
  margin-bottom: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.app-name span[data-v-3c0cad79] {
    padding: 3px 5px;
    border: 1px solid #fff;
    font-size: 12px;
    color: #fff;
}
