[data-v-126b135a]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
.setting-drawer-content .setting-drawer-title[data-v-126b135a] {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.setting-drawer-content .setting-drawer-block-checbox[data-v-126b135a] {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.setting-drawer-content .setting-drawer-block-checbox .setting-drawer-block-checbox-item[data-v-126b135a] {
    position: relative;
    border-radius: 2px;
    margin-right: 16px;
    cursor: pointer;
}
.setting-drawer-content .setting-drawer-block-checbox .setting-drawer-block-checbox-item img[data-v-126b135a] {
      width: 80px;
}
.setting-drawer-content .setting-drawer-block-checbox .setting-drawer-block-checbox-item .el-icon-check[data-v-126b135a] {
      position: absolute;
      left: 42%;
      top: 27%;
      font-size: 20px;
      font-weight: 700;
      color: #4ecb73;
}
.drawer-container[data-v-126b135a] {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.drawer-container .drawer-title[data-v-126b135a] {
    margin-bottom: 12px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
}
.drawer-container .drawer-item[data-v-126b135a] {
    padding: 12px 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
}
.drawer-container .drawer-switch[data-v-126b135a] {
    float: right;
}
