[data-v-35ce65f4]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
.logo[data-v-35ce65f4] {
  position: relative;
  display: flex !important;
  overflow: hidden;
  z-index: 1;
  height: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.logo img[data-v-35ce65f4] {
    width: 34%;
}
.logo span[data-v-35ce65f4] {
    display: block;
    overflow: hidden;
    width: 90%;
    margin: 10px auto 0;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
