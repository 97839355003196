@charset "UTF-8";
[data-v-2d4412df]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
.tool[data-v-2d4412df] {
  position: fixed;
  z-index: 1000;
  display: inline-flex;
  right: -14px;
  bottom: 60px;
  padding: 8px 16px;
  width: 150px;
  height: 40px;
  align-items: center;
  gap: 16px;
  border-radius: 20px 0 0 20px;
  background: #456FF7;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.2);
  color: #FFF;
  font-size: 16px;
  opacity: 0.4;
  transition: opacity 0.8s;
  /* 可以设置不同的进入和离开动画 */
  /* 设置持续时间和动画函数 */
}
.tool[data-v-2d4412df]:hover {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.8s;
}
.tool.active[data-v-2d4412df] {
    opacity: 1;
}
.tool > div[data-v-2d4412df] {
    position: relative;
}
.tool > div .label[data-v-2d4412df] {
      margin-left: 40px;
}
.tool > div .icon[data-v-2d4412df] {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.2);
      position: absolute;
}
.tool > div .icon.set[data-v-2d4412df] {
        background: transparent;
        padding: 0;
        opacity: 0;
}
.tool > div .icon.set svg[data-v-2d4412df] {
          width: 24px;
          height: 24px;
}
.tool .popover-area[data-v-2d4412df] {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    right: 32px;
    align-self: flex-start;
    transform: translateY(calc(-100% - 28px));
    min-width: 188px;
}
.tool .popover-area .popover-box[data-v-2d4412df] {
      flex: 1 auto;
      padding: 24px 30px;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 12px;
      background: #FFF;
      box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.2);
      color: #333;
      font-size: 16px;
      line-height: 46px;
}
.tool .popover-area .popover-box.menus li.success-status[data-v-2d4412df]:hover {
        color: #456FF7;
}
.tool .popover-area .popover-box.menus li[data-v-2d4412df] {
        display: flex;
        align-items: center;
        white-space: nowrap;
        width: 100%;
}
.tool .popover-area .popover-box.menus li .svg-icon[data-v-2d4412df] {
          margin-right: 16px;
}
.tool .popover-area .popover-box.menus li .name[data-v-2d4412df] {
          display: inline-block;
          min-width: 128px;
          margin-right: 8px;
}
.tool .popover-area .popover-box.menus li .operate-area[data-v-2d4412df] {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
}
.tool .popover-area .popover-box.tips[data-v-2d4412df] {
        line-height: 22px;
}
.tool .slide-fade-enter-active[data-v-2d4412df] {
    transition: all .3s ease;
}
.tool .slide-fade-leave-active[data-v-2d4412df] {
    transition: all .3s ease;
}
.tool .slide-fade-enter[data-v-2d4412df], .tool .slide-fade-leave-to[data-v-2d4412df] {
    transform: translateX(100%) translateY(calc(-100% - 28px));
    opacity: 0;
}
