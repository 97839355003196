[data-v-4e777e8c]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
.text-link[data-v-4e777e8c],
.text-link-disabled[data-v-4e777e8c] {
  display: inline;
  width: 100%;
}
.text-link[data-v-4e777e8c] {
  color: #456ff7;
  cursor: pointer;
}
.text-link-disabled[data-v-4e777e8c] {
  color: #c0c4cc;
  cursor: not-allowed;
}
