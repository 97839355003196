[data-v-be6b7bae]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
.scroll-container[data-v-be6b7bae] {
  position: relative;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.scroll-container[data-v-be6b7bae] .el-scrollbar__bar {
    bottom: 0;
}
.scroll-container[data-v-be6b7bae] .el-scrollbar__wrap {
    display: flex;
    height: 100%;
    align-items: center;
    overflow-x: hidden;
}
