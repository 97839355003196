[data-v-15a9fdfb]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
img[data-v-15a9fdfb] {
  max-width: 100%;
}
.img-list[data-v-15a9fdfb] {
  position: relative;
}
.img-list .el-button[data-v-15a9fdfb] {
    position: absolute;
    top: 50%;
    margin-top: -28px;
    font-size: 30px;
}
.img-list .el-button.el-icon-arrow-left[data-v-15a9fdfb] {
      left: -20px;
}
.img-list .el-button.el-icon-arrow-right[data-v-15a9fdfb] {
      right: -20px;
}
.pagination-container[data-v-15a9fdfb] {
  padding-bottom: 0 !important;
}
[data-v-15a9fdfb] .el-drawer__header {
  margin-bottom: 20px;
  font-weight: bold;
  color: #333;
}
[data-v-15a9fdfb] .el-table__body-wrapper {
  overflow: auto;
  max-height: calc(100vh - 67px - 44px - 44px - 72px);
  overflow-x: hidden;
}
