
.svg-icon[data-v-c8a70580] {
  overflow: hidden;
  width: 18px;
  height: 18px;

  /* vertical-align: -0.15em; */
  fill: currentcolor;
}
.svg-external-icon[data-v-c8a70580] {
  display: inline-block;
  background-color: currentcolor;
  mask-size: cover !important;
}
