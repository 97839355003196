[data-v-232f54ce]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
.sidebarLogoFade-enter-active[data-v-232f54ce] {
  transition: opacity 1.5s;
}
.sidebarLogoFade-enter[data-v-232f54ce],
.sidebarLogoFade-leave-to[data-v-232f54ce] {
  opacity: 0;
}
.sidebar-logo-container[data-v-232f54ce] {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 66px;
  line-height: 66px;
}
.sidebar-logo-container .sidebar-logo-link[data-v-232f54ce] {
    width: 100%;
    height: 100%;
}
.sidebar-logo-container .sidebar-logo-link .sidebar-logo[data-v-232f54ce] {
      width: 50px;
      margin-right: 12px;
      margin-left: 24px;
      vertical-align: middle;
}
.sidebar-logo-container .sidebar-logo-link .sidebar-title[data-v-232f54ce] {
      display: inline-block;
      margin: 0;
      font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-size: 14px;
      font-weight: 600;
      vertical-align: middle;
      line-height: 50px;
}
