[data-v-541c1922]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
.main-title[data-v-541c1922] {
  height: 50px;
  padding: 0 20px;
  border-bottom: 1px solid #f4f6f9;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  line-height: 50px;
}
.child[data-v-541c1922] {
  font-size: 14px;
}
.child-item[data-v-541c1922] {
    padding-left: 4px;
}
.theme-light .main-title[data-v-541c1922] {
  border-bottom: 1px solid #fff;
  color: #333;
}
