[data-v-5de8be5e]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
[data-v-5de8be5e] .el-drawer__header {
  margin-bottom: 20px;
  font-weight: bold;
  color: #333;
}
[data-v-5de8be5e] .el-table__body-wrapper {
  overflow: auto;
  max-height: calc(100vh - 67px - 44px - 72px);
  overflow-x: hidden;
}
