
.row-chosen {
  background-color: #fff;
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.1));
}
.sort-icon {
  display: inline-block;
  width: 14px;
  height: 12px;
  border-color: #848484;
  border-top: 7px double;
  border-bottom: 2px solid;
  cursor: move;
}
.header-cell {
  border-bottom: 1px solid #e8e8e8;
  background-color: #f8f8f9;
  font-weight: 600;
  color: #515a6e;
}
.row {
  border-bottom: 1px solid #e8e8e8;
}
.header-cell,
.row-cell {
  overflow: hidden;
  height: 40px;
  padding: 0 8px;
  font-size: 13px;
  text-align: center;
  text-overflow: ellipsis;
  line-height: 40px;
  white-space: nowrap;
  word-break: break-word;
}
