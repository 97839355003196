[data-v-4a405020]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
.notice-card-wrapper .inner-container[data-v-4a405020] {
  display: flex;
  width: 100%;
  margin-left: calc(100vw - 200px);
  animation: myMove-4a405020 30s linear infinite;
  animation-fill-mode: forwards;
}
.notice-card-wrapper .inner-container *[data-v-4a405020] {
    display: inline-block !important;
}
.notice-card-wrapper .inner-container[data-v-4a405020]:hover {
    animation-play-state: paused;
}
@keyframes myMove-4a405020 {
0% {
    transform: translateX(0);
}
100% {
    transform: translateX(-100vw);
}
}
