[data-v-13877386]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
[data-v-13877386]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
.app-wrapper[data-v-13877386] {
  position: relative;
  width: 100%;
  height: 100%;
}
.app-wrapper[data-v-13877386]::after {
    content: '';
    display: table;
    clear: both;
}
.app-wrapper.mobile.openSidebar[data-v-13877386] {
    position: fixed;
    top: 0;
}
.drawer-bg[data-v-13877386] {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
}
.fixed-header[data-v-13877386] {
  position: fixed;
  z-index: 11;
  width: 100%;
  transition: width 0.28s;
}
.hideSidebar .fixed-header[data-v-13877386] {
  width: calc(100% - 54px);
}
.pcOpenSidebar[data-v-13877386] {
  margin-left: 340px !important;
}
.pcOpenSidebar .fixed-header[data-v-13877386] {
    width: calc(100% - 340px) !important;
}
.gdprOpen[data-v-13877386] {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.gdpr-mask[data-v-13877386] {
  position: absolute;
  inset: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
}
.gdpr[data-v-13877386] {
  position: absolute;
  inset: 0;
  width: 1160px;
  height: 700px;
  padding: 30px;
  border-radius: 10px;
  margin: auto;
  background-color: #fff;
}
.gdpr-header-title[data-v-13877386] {
    font-size: 30px;
    color: #d20030;
}
.gdpr-header-logo[data-v-13877386] {
    margin-top: 10px;
}
.gdpr-version[data-v-13877386] {
    margin: 20px 0;
    font-size: 12px;
    color: #999;
}
.gdpr-version span[data-v-13877386] {
      margin-right: 30px;
}
.gdpr-tab div[data-v-13877386] {
    display: inline-block;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 10px;
}
.gdpr-tab span[data-v-13877386] {
    display: inline-block;
    height: 36px;
    padding: 0 20px;
    background: #f8f8f8;
    font-size: 15px;
    color: #a1a1a1;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
}
.gdpr-tab .active[data-v-13877386] {
    background: #cdd9ff;
    color: #456ff7;
}
.gdpr-container[data-v-13877386] {
    height: 430px;
    background: #f2f2f2;
}
.gdpr-container div[data-v-13877386] {
      height: 430px;
      padding: 10px;
      line-height: 2;
      overflow-x: hidden;
      overflow-y: auto;
}
.gdpr-container .p1[data-v-13877386] {
      text-indent: 2em;
}
.gdpr-container .p-text-r[data-v-13877386] {
      text-align: right;
}
.gdpr-container h1[data-v-13877386] {
      font-size: 20px;
      font-weight: bold;
}
.gdpr-container .en-p[data-v-13877386] {
      margin: 10px 0;
}
.gdpr-container .en-p-no[data-v-13877386] {
        display: block;
        float: left;
        width: 3%;
}
.gdpr-container .en-p-text[data-v-13877386] {
        display: block;
        float: right;
        width: 97%;
}
.gdpr-container .en-p-text .en-p-no[data-v-13877386] {
          display: block;
          float: left;
          width: 4.5%;
}
.gdpr-container .en-p-text .en-p-text[data-v-13877386] {
          display: block;
          float: right;
          width: 95.5%;
}
.gdpr-foot[data-v-13877386] {
    margin-top: 20px;
}
.gdpr-foot .el-checkbox[data-v-13877386] {
      float: left;
      margin-top: 7px;
}
.gdpr-foot-btn[data-v-13877386] {
      float: right;
}
