@charset "UTF-8";
[data-v-836ef654]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
[data-v-836ef654]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
.child-item[data-v-836ef654] {
  display: flex !important;
  height: 45px;
  padding-left: 12px;
  border-radius: 8px 0 0 8px;
  margin: 5px 0;
  font-size: 14px;
  color: #666;
  line-height: 45px;
  align-items: center;
  cursor: pointer;
}
.child-item[data-v-836ef654]::before {
    display: block;
    width: 8px;
    height: 8px;
    border: 1px solid #9f9f9f;
    border-radius: 100%;
    margin-right: 10px;
    content: '';
}
.child-item[data-v-836ef654]:hover {
    background: -webkit-linear-gradient(left, #0a0c2d, blue);
    /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #0a0c2d, #3450c9);
    /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #0a0c2d, #3450c9);
    /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #0a0c2d, #3450c9);
    /* 标准的语法 */
    color: #fff;
}
.child-item[data-v-836ef654]:hover::before {
      border-color: #fff;
      background: #fff;
}
.router-link-active[data-v-836ef654] {
  background: -webkit-linear-gradient(left, #0a0c2d, blue);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(right, #0a0c2d, #3450c9);
  /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(right, #0a0c2d, #3450c9);
  /* Firefox 3.6 - 15 */
  background: linear-gradient(to right, #0a0c2d, #3450c9);
  /* 标准的语法 */
  color: #fff;
}
.router-link-active[data-v-836ef654]::before {
    border-color: #fff;
    background: #fff;
}
.menu-item .child-item[data-v-836ef654] {
  position: relative;
}
.menu-item .child-item i[data-v-836ef654] {
    position: absolute;
    right: 27px;
}
.menu-item .nest-menu[data-v-836ef654] {
  display: none;
}
.menu-item .nest-menu .child-item[data-v-836ef654] {
    padding-left: 30px;
}
.menu-item .nest-menu .child-item[data-v-836ef654]::before {
      display: none;
}
.active[data-v-836ef654] {
  border-radius: 8px 0 0 8px;
}
.active .child-item i[data-v-836ef654] {
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    /* IE 9 */
    /* Firefox */
    /* Safari 和 Chrome */
    /* Opera */
}
.active .nest-menu[data-v-836ef654] {
    display: block;
}
.theme-light .router-link-active[data-v-836ef654] {
  background: #f1f3f9;
  color: #456ff7;
}
.theme-light .router-link-active[data-v-836ef654]::before {
    border-color: #456ff7;
    background: #456ff7;
}
.theme-light .child-item[data-v-836ef654] {
  color: #666;
}
.theme-light .child-item[data-v-836ef654]:hover {
    background: #f1f3f9;
    color: #666;
}
.theme-light .child-item[data-v-836ef654]:hover::before {
      border-color: #456ff7;
      background: #456ff7;
}
