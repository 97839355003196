[data-v-c401938e]:export {
  theme: #456ff7;
  menuText: #bfcbd9;
  menuActiveText: #409eff;
  subMenuActiveText: #f4f4f5;
  menuBg: #0a0c2c;
  menuHover: #263445;
  menuLightBg: #fff;
  menuLightHover: #f0f1f5;
  sideBarWidth: 140px;
  sideBarWidthTwo: 200px;
  sidebarTitle: #ffffff;
  sidebarLightTitle: #001529;
  twoMenuColor: #bfcbd9;
  twoMenuLightColor: rgba(0, 0, 0, 0.7);
  twoMenuBackground: #0a0c2c;
  twoMenuLightBackground: #ffffff;
  twoSideBarWidth: 230px;
}
.page[data-v-c401938e] {
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.input-block[data-v-c401938e] {
  width: 200px;
}
.input-block .el-row[data-v-c401938e] {
    margin-bottom: 20px;
}
.input-block .el-col[data-v-c401938e] {
    margin-bottom: 10px;
}
