@charset "UTF-8";

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  padding: 0;
  margin: 0; }

body,
button,
input,
select,
textarea {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', Arial, sans-serif; }

body {
  min-width: 1200px;
  height: 100%;
  background-color: #f5f7fb;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  -webkit-text-size-adjust: none; }

body,
a {
  color: #666; }

.title-color {
  color: #333; }

.info-color {
  color: #999; }

html {
  height: 100%;
  box-sizing: border-box;
  font-size: 14px; }

#app {
  height: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.no-padding {
  padding: 0 !important; }

ul {
  padding: 0;
  margin: 0; }

li {
  list-style: none; }

.tl {
  text-align: left; }

.tc {
  text-align: center; }

.tr {
  text-align: right; }

h1,
h2,
h3,
h4,
h5 {
  font-size: 100%;
  font-weight: normal; }

em,
i {
  font-style: normal; }

a,
a:focus,
a:hover {
  cursor: pointer;
  text-decoration: none; }

a:focus,
a:active,
div,
button,
div:focus,
button:focus,
button:hover,
button:active {
  outline: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.fr {
  float: right; }

.fl {
  float: left; }

.block {
  display: block; }

.none {
  display: none; }

.hidden {
  visibility: hidden; }

.pointer {
  cursor: pointer; }

.clearfix::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  visibility: hidden;
  content: ' '; }

.vm {
  display: inline-block;
  vertical-align: middle; }

.vt {
  display: inline-block;
  vertical-align: top; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal; }

.text-o {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-ellipsis2 {
  display: -webkit-box;
  /* 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。 */
  overflow: hidden;
  text-overflow: ellipsis;
  /* 可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。 */
  -webkit-box-orient: vertical;
  /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。 */
  -webkit-line-clamp: 2; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-left {
  text-align: left !important; }

.ft12 {
  font-size: 12px; }

.ft14 {
  font-size: 14px; }

.ft16 {
  font-size: 16px; }

.ft18 {
  font-size: 18px; }

.ft20 {
  font-size: 20px; }

::-webkit-scrollbar {
  width: 8px;
  height: 8px; }

::-webkit-scrollbar-track-piece {
  border-radius: 6px;
  background-color: transparent; }

::-webkit-scrollbar-thumb:vertical {
  height: 8px;
  border-radius: 6px;
  background-color: #bdbdbd; }

::-webkit-scrollbar-thumb:horizontal {
  width: 8px;
  border-radius: 6px;
  background-color: #bdbdbd; }

::-webkit-scrollbar-thumb:vertical:hover,
::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: rgba(0, 0, 0, 0.3); }

.scrollbar::-webkit-scrollbar {
  width: 6px; }

.scrollbar::-webkit-scrollbar-thumb:vertical {
  background-color: #dfe0eb; }

.el-table .el-table__header-wrapper th,
.el-table .el-table__fixed-header-wrapper th {
  height: 40px;
  background-color: #f8f8f9;
  font-size: 13px;
  color: #515a6e;
  word-break: break-word; }

.el-table .el-table__body-wrapper .el-button [class*='el-icon-'] + span {
  margin-left: 1px; }

.el-table .cell .el-button {
  padding: 0 !important; }

.el-table .el-table__fixed-right {
  height: 100% !important; }

.el-tree {
  overflow: auto; }

.el-tree-node > .el-tree-node__children {
  overflow: inherit !important; }

.vue-treeselect__label-container {
  display: inline-block;
  width: auto !important;
  vertical-align: middle;
  white-space: nowrap; }

.vue-treeselect__menu {
  overflow-x: auto !important; }

.el-select__tags {
  overflow-x: auto; }

.el-tabs__nav-wrap::after {
  background-color: #eff1f8; }

.el-popconfirm__main {
  margin-bottom: 10px; }

.badge-text {
  display: flex;
  align-items: center; }
  .badge-text::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    margin-right: 8px; }

.flex-center.detail-content {
  position: relative; }
  .flex-center.detail-content .no-data {
    height: 250px;
    margin: 60px; }
    .flex-center.detail-content .no-data img {
      height: 100%; }

.primary {
  color: #456ff7; }

.success {
  color: #02b156; }

.warning {
  color: #fd9a3f; }

.danger {
  color: #f24750; }

.info {
  color: #999; }

.badge-primary::before {
  background-color: #456ff7; }

.badge-success::before {
  background-color: #02b156; }

.badge-warning::before {
  background-color: #fd9a3f; }

.badge-danger::before {
  background-color: #f24750; }

.badge-info::before {
  background-color: #999; }

.el-drawer__header span {
  font-size: 16px; }

.el-drawer__body {
  padding: 0 20px 20px; }

.back-head {
  border-bottom: 1px solid #d8dce5;
  margin-bottom: 20px; }
  .back-head-left p {
    padding: 10px 5px;
    margin: 0 15px;
    font-size: 16px;
    color: #333;
    align-items: center; }
  .back-head-btn {
    padding-right: 10px;
    border-right: 1px solid #e0e0e0;
    font-size: 18px;
    cursor: pointer; }

.head-container {
  padding: 0 0 16px; }

.tooltip-line {
  min-width: 110px;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #fff;
  color: #666;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08); }
  .tooltip-line::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    margin: 0 0 0 -7px;
    width: 0;
    height: 0;
    border-top: 8px solid #fff;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent; }
  .tooltip-line .tooltip-num {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    line-height: 36px; }
  .tooltip-line .tooltip-title {
    font-size: 12px; }

.table-bg {
  background: #f8f8f9; }

.base-info {
  padding-bottom: 40px; }
  .base-info h3 {
    padding: 0 0 10px;
    color: #333333; }
  .base-info .table-row {
    width: 100%;
    border-top: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6; }
    .base-info .table-row .el-col {
      overflow: hidden;
      height: 42px;
      padding: 10px;
      border: 1px solid #e6e6e6;
      border-top: none;
      border-left: none;
      text-overflow: ellipsis;
      white-space: nowrap; }

.el-form-query {
  margin-right: -10px; }

.title-line {
  position: relative;
  padding-left: 10px;
  margin: 24px 0;
  font-weight: bold;
  color: #333333; }
  .title-line::before {
    position: absolute;
    left: 0;
    top: 50%;
    width: 2px;
    height: 14px;
    margin-top: -7px;
    background: #fb0214;
    content: ''; }

.height-all {
  height: 100%; }

.queryForm .form-item-query {
  max-width: 190px; }

.btn-fixed {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  padding: 10px 50px 10px 360px;
  margin-bottom: 0 !important;
  background: #fff;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08); }

.status {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 5px; }
  .status-primary {
    background: #456ff7; }
  .status-online, .status-success {
    background: #02b156; }
  .status-offline {
    background: rgba(0, 0, 0, 0.25); }
  .status-warning {
    background: #fd9a3f; }
  .status-error, .status-danger {
    background: #f24750; }

@media screen and (max-width: 1510px) {
  .base-info .table-row .el-col {
    font-size: 13px; } }

.swiperOption-pagination .swiper-pagination-bullet {
  width: 16px;
  height: 3px;
  border-radius: 1px;
  margin: 0 1px !important;
  background: #e7e7e7;
  opacity: 1;
  cursor: pointer; }
  .swiperOption-pagination .swiper-pagination-bullet-active {
    background: #456ff7; }

.scrollbar-cont .el-scrollbar__wrap {
  overflow-x: hidden; }

.scrollbar-cont .is-horizontal {
  display: none; }

.el-card.is-always-shadow {
  border: none;
  border-radius: 2px;
  box-shadow: none; }

.el-table--small {
  font-size: 14px; }

.el-table tr td,
.el-table tr th {
  overflow: hidden; }
  .el-table tr td .cell,
  .el-table tr th .cell {
    overflow: hidden;
    padding: 0 8px !important;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .el-table tr td .cell .el-button,
    .el-table tr th .cell .el-button {
      padding-right: 0;
      padding-left: 0; }

.avatar-uploader .el-upload {
  position: relative;
  overflow: hidden;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer; }

.avatar-uploader .el-upload,
.avatar-uploader .avatar {
  width: 178px;
  height: 178px; }

.avatar-uploader .avatar-uploader-icon {
  font-size: 28px; }

.avatar-uploader .el-upload:hover {
  border-color: #409eff; }

.el-card .el-card__header {
  padding: 10px 0;
  margin: 0 20px;
  font-size: 16px;
  line-height: 40px; }

.card-title {
  font-size: 18px;
  font-weight: 700;
  color: #333; }

.el-form-item .el-form-item {
  margin-bottom: 0 !important; }

.width-all,
.el-date-editor.el-input.width-all {
  width: 100%; }

.el-tooltip__popper {
  max-width: 500px; }

.el-drawer__header span {
  outline: none; }

.el-form-item.is-error .vue-treeselect__control {
  border-color: #f24750; }

.el-descriptions__title {
  font-size: 16px;
  font-weight: normal;
  color: #1e1e1e; }

.el-descriptions__body table th,
.el-descriptions__body table td {
  border: 1px solid #ebedf0;
  font-size: 14px;
  line-height: 28px; }
  .el-descriptions__body table th .q-avatar,
  .el-descriptions__body table td .q-avatar {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    line-height: 24px; }

.el-descriptions .is-bordered th,
.el-descriptions .is-bordered td {
  padding: 8px; }

.el-descriptions-item__label.is-bordered-label {
  overflow: hidden;
  min-width: 160px;
  background: #f8f8f9;
  font-weight: normal;
  color: #333;
  table-layout: fixed;
  word-break: break-all; }

.el-descriptions-item__content {
  overflow: hidden;
  width: 270px;
  font-weight: 600;
  color: #868686;
  table-layout: fixed;
  word-break: break-all; }
  .el-descriptions-item__content .q-badge-text {
    display: flex;
    align-items: center; }
    .el-descriptions-item__content .q-badge-text::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      margin-right: 8px; }

.my-content-avatar {
  padding: 10px 0 !important;
  line-height: 24px !important; }

.el-dialog__header {
  height: 52px;
  background: #fafafc;
  text-align: center; }

.el-dialog__body {
  padding: 30px 30px 24px; }

.el-dialog__footer {
  padding: 0 30px 30px; }

.el-form--label-top .el-form-item__label {
  padding-bottom: 5px; }

.el-select .el-tag {
  border-color: #dae2fd;
  margin-right: 0 !important;
  background-color: #ecf1fe;
  color: #456ff7; }
  .el-select .el-tag .el-tag__close,
  .el-select .el-tag .el-tag__close:hover {
    background-color: #fff0;
    color: #456ff7; }

.el-tabs__item {
  height: auto;
  margin-bottom: 20px;
  line-height: normal; }

.reset-input:not(.el-select.el-input) {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 3px; }
  .reset-input:not(.el-select.el-input) .el-input__inner {
    flex: 1;
    border: none; }
  .reset-input:not(.el-select.el-input) .el-input__suffix {
    position: relative; }

.reset-input.reset-input-focus {
  border-color: #456ff7 !important; }

.el-form-item.is-error .reset-input {
  border-color: #f24750; }

.el-message {
  min-width: 248px;
  height: 54px;
  padding: 13px 24px;
  border-color: #fff;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2); }
  .el-message i:nth-child(1) {
    margin-right: 8px;
    font-size: 30px; }
  .el-message p {
    font-size: 16px;
    color: #333; }

.iconinvalid {
  color: #e34d59; }

.iconnew_news {
  color: #1782ff; }

.iconsuccess {
  color: #16c787; }

.iconunable {
  color: #e34d59; }

.iconwarning {
  color: #ff6108; }

.q-confirm {
  width: 520px;
  min-height: 234px;
  padding: 17px 17px 32px; }
  .q-confirm .el-message-box__header {
    font-size: 16px;
    color: #333; }
  .q-confirm .el-message-box__content {
    padding: 15px; }
  .q-confirm .el-message-box__status {
    font-size: 32px !important; }
  .q-confirm .el-message-box__message {
    margin-left: 12px;
    font-size: 16px;
    color: #333; }
  .q-confirm .el-message-box__btns {
    margin-top: 40px; }

.el-message-box__btns .del {
  border-color: #fbc4c4;
  background-color: #e34d59; }
  .el-message-box__btns .del:hover {
    background-color: #f78989; }

.img-cell .el-table .cell.el-tooltip .el-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; }

.img-cell .el-table .cell.el-tooltip .el-image {
  width: 16px;
  height: 16px;
  margin-right: 4px; }

.img-cell .el-table .cell.el-tooltip .file-path .el-image__inner {
  width: 16px;
  height: 16px; }

.text-btn {
  overflow: hidden;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .text-btn:nth-child(2) {
    margin-top: 8px; }

.info-wrap .info-item {
  padding: 3px 0;
  font-size: 12px;
  color: #999; }
  .info-wrap .info-item span {
    color: #333; }

.gm-style {
  /* 谷歌marker-label样式 */ }
  .gm-style .cluster span {
    color: #fff; }
  .gm-style .google-marker-label {
    position: relative;
    top: -35px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #666 !important; }
  .gm-style .gm-style-iw-c {
    font-weight: normal;
    outline: none;
    transition: all 0.2s; }
  .gm-style .info-wrap .info-item {
    padding: 5px 0; }

.map-track {
  height: 100%;
  min-height: 500px; }
  .map-track .gm-ui-hover-effect {
    display: none !important; }

.pac-container {
  z-index: 9999; }

.pac-target-input {
  position: absolute;
  left: auto !important;
  top: 10px !important;
  right: 70px;
  z-index: 2;
  width: 300px;
  font-size: 14px; }

.bd-wrap {
  position: relative; }

.map-box {
  position: relative; }
  .map-box .map-btn {
    position: absolute;
    left: 20px;
    top: 10px;
    z-index: 2; }
    .map-box .map-btn .el-button {
      height: 40px;
      border: none;
      background-color: #fff;
      font-size: 16px;
      color: #333;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12); }
      .map-box .map-btn .el-button:hover {
        background-color: #eee; }
    .map-box .map-btn .iconfont {
      margin-right: 5px; }
  .map-box.gmap .map-btn {
    left: 210px; }
  .map-box.gmap .g-input {
    display: none; }

.mapContainer-Clusterer {
  height: 100%; }
  .mapContainer-Clusterer > .map-el {
    height: 100% !important; }

.map-msg {
  font-size: 14px;
  text-align: left;
  line-height: 1.8; }
  .map-msg .title {
    overflow: hidden;
    border-bottom: 1px solid #e6e6e6;
    margin: -10px 0 15px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-overflow: ellipsis;
    line-height: 48px;
    white-space: nowrap; }
  .map-msg .el-btn {
    padding-top: 8px;
    border-top: 1px solid #e8e8e8;
    margin-top: 20px; }
    .map-msg .el-btn .el-button--text {
      position: relative;
      float: left;
      width: 50%;
      padding: 8px 5px;
      margin-left: 0;
      font-size: inherit;
      text-align: center; }
      .map-msg .el-btn .el-button--text:nth-of-type(2n)::before {
        position: absolute;
        left: 0;
        top: 50%;
        width: 1px;
        height: 26px;
        margin-top: -13px;
        background: #e8e8e8;
        content: ''; }
    .map-msg .el-btn .el-button--text {
      position: relative;
      float: left;
      overflow: hidden;
      width: 50%;
      padding: 8px 5px;
      margin-left: 0;
      font-size: inherit;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .map-msg .el-btn .el-button--text:nth-of-type(2n)::before {
        position: absolute;
        left: 0;
        top: 50%;
        width: 1px;
        height: 26px;
        margin-top: -13px;
        background: #e8e8e8;
        content: ''; }
  .map-msg .el-button--text.hand {
    border-bottom: 1px solid #409eff;
    font-style: normal; }
  .map-msg label {
    display: inline-block;
    overflow: hidden;
    max-width: 200px;
    height: 22px;
    color: #333;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .map-msg div > span {
    display: inline-block;
    height: 22px;
    color: #666;
    vertical-align: middle; }
  .map-msg a,
  .map-msg a:hover {
    color: #409eff; }
  .map-msg .battery-snNo {
    margin-right: 15px; }
  .map-msg .battery-eng {
    padding: 2px 5px;
    border: 1px solid #000;
    color: #fff; }
  .map-msg .map-btn a {
    display: inline-block;
    float: left;
    width: 25%;
    margin: 4px 0; }

.map-box-en .el-btn .el-button--text {
  width: 45%;
  font-size: 12px; }
  .map-box-en .el-btn .el-button--text:nth-of-type(2n) {
    width: 55%; }

.map-info-block .map-info li {
  padding: 0 0 5px; }
  .map-info-block .map-info li label,
  .map-info-block .map-info li .txt {
    display: block;
    overflow: hidden;
    height: 22px;
    text-overflow: ellipsis;
    white-space: nowrap; }

.map-box-en .el-btn .el-button--text {
  width: 45%;
  font-size: 12px; }
  .map-box-en .el-btn .el-button--text:nth-of-type(2n) {
    width: 55%; }

.amap-marker-label {
  top: -48px !important;
  padding: 12px !important;
  border: none !important;
  border-radius: 27px;
  margin-left: -24px;
  font-family: 'PingFang SC' !important;
  font-weight: 500 !important;
  color: #333;
  transform: translateX(-50%); }
  .amap-marker-label .online-status {
    margin-left: 5px; }
  .amap-marker-label .online {
    color: #35cd60; }
  .amap-marker-label .offline {
    color: #b5b5b5; }

.amap-info-content {
  overflow: hidden;
  padding: 24px;
  border-radius: 4px;
  filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.1));
  box-shadow: none !important; }
  .amap-info-content > div {
    height: 100%;
    padding-right: 24px;
    margin-right: -24px;
    overflow-y: auto; }
  .amap-info-content .amap-info-close {
    right: 5px !important; }

.amap-info-sharp {
  display: none; }

#app .main-container {
  position: relative;
  min-height: 100%;
  transition: margin-left 0.28s; }

#app .sidebar-container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1888;
  height: 100%;
  font-size: 0;
  -webkit-transition: width 0.28s;
  transition: width 0.28s; }
  #app .sidebar-container .horizontal-collapse-transition {
    transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out; }
  #app .sidebar-container .scrollbar-wrapper {
    overflow-x: hidden !important; }
  #app .sidebar-container .el-scrollbar__bar.is-vertical {
    right: 0; }
  #app .sidebar-container .el-scrollbar {
    height: 100%; }
  #app .sidebar-container .is-horizontal {
    display: none; }
  #app .sidebar-container a {
    display: inline-block;
    width: 100%; }
  #app .sidebar-container .svg-icon {
    margin-right: 16px; }
  #app .sidebar-container .el-menu {
    width: 100% !important;
    height: 100%;
    border: none;
    background: none !important; }
  #app .sidebar-container .el-menu-item,
  #app .sidebar-container .el-submenu__title {
    overflow: hidden !important;
    height: 50px !important;
    padding: 0 24px !important;
    text-overflow: ellipsis !important;
    line-height: 50px !important;
    white-space: nowrap !important; }
  #app .sidebar-container .theme-dark .is-active > .el-submenu__title {
    color: #f4f4f5 !important; }
  #app .sidebar-container .sidebar-two .theme-light .el-menu-item.is-active {
    background: linear-gradient(90deg, #3f4aea 0%, #3a90f5 100%) !important; }
    #app .sidebar-container .sidebar-two .theme-light .el-menu-item.is-active:hover {
      color: #fff !important; }
  #app .sidebar-container .sidebar-two .theme-light .el-submenu .el-menu-item.is-active {
    background: linear-gradient(90deg, #3f4aea 0%, #3a90f5 100%) !important; }
    #app .sidebar-container .sidebar-two .theme-light .el-submenu .el-menu-item.is-active:hover {
      background: linear-gradient(90deg, #3f4aea 0%, #3a90f5 100%) !important;
      color: #fff !important; }
  #app .sidebar-container .sidebar-two .theme-light .submenu-title-noDropdown:hover,
  #app .sidebar-container .sidebar-two .theme-light .el-submenu__title:hover {
    color: #456ff7 !important; }
  #app .sidebar-container .sidebar-two .theme-light .el-submenu .el-menu-item:hover {
    color: #456ff7 !important; }
  #app .sidebar-container .sidebar-two .theme-dark .el-menu-item.is-active {
    background: linear-gradient(90deg, rgba(63, 74, 234, 0.6) 0%, rgba(58, 144, 245, 0.6) 100%) !important; }
  #app .sidebar-container .sidebar-two .theme-dark .nest-menu .el-menu-item:hover span {
    color: #fff; }
  #app .sidebar-container .sidebar-two .theme-dark .nest-menu .el-submenu > .el-submenu__title,
  #app .sidebar-container .sidebar-two .theme-dark .el-submenu .el-menu-item {
    background-color: #07081e !important; }
  #app .sidebar-container .sidebar-two .submenu-title-noDropdown:hover,
  #app .sidebar-container .sidebar-two .el-submenu__title:hover {
    background: none !important;
    color: #fff !important; }
  #app .sidebar-container .sidebar-two .nest-menu .el-submenu > .el-submenu__title,
  #app .sidebar-container .sidebar-two .el-submenu .el-menu-item {
    padding-left: 50px !important; }
    #app .sidebar-container .sidebar-two .nest-menu .el-submenu > .el-submenu__title:hover,
    #app .sidebar-container .sidebar-two .el-submenu .el-menu-item:hover {
      background: none; }
  #app .sidebar-container .sidebar-two .nest-menu {
    position: relative; }
    #app .sidebar-container .sidebar-two .nest-menu::before {
      position: absolute;
      display: block;
      left: 25px;
      top: 23px;
      z-index: 1;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: #fff;
      content: ''; }
    #app .sidebar-container .sidebar-two .nest-menu .svg-icon {
      display: none; }

#app .hideSidebar .sidebar-container {
  width: 54px !important; }

#app .hideSidebar .main-container {
  margin-left: 54px; }

#app .hideSidebar .submenu-title-noDropdown {
  position: relative;
  padding: 0 !important; }
  #app .hideSidebar .submenu-title-noDropdown .el-tooltip {
    padding: 0 !important; }
    #app .hideSidebar .submenu-title-noDropdown .el-tooltip .svg-icon {
      margin-left: 20px; }

#app .hideSidebar .el-submenu {
  overflow: hidden; }
  #app .hideSidebar .el-submenu > .el-submenu__title {
    padding: 0 !important; }
    #app .hideSidebar .el-submenu > .el-submenu__title .svg-icon {
      margin-left: 24px; }

#app .hideSidebar .el-menu--collapse .el-submenu > .el-submenu__title > span {
  display: inline-block;
  overflow: hidden;
  width: 0;
  height: 0;
  visibility: hidden; }

#app .el-menu--collapse .el-menu .el-submenu {
  min-width: 140px !important; }

#app .mobile .main-container {
  margin-left: 0; }

#app .mobile .sidebar-container {
  width: 140px !important;
  transition: transform 0.28s; }

#app .mobile.hideSidebar .sidebar-container {
  pointer-events: none;
  transition-duration: 0.3s;
  transform: translate3d(-140px, 0, 0); }

#app .withoutAnimation .main-container,
#app .withoutAnimation .sidebar-container {
  transition: none; }

.el-menu--vertical > .el-menu .svg-icon {
  margin-right: 16px; }

.el-menu--vertical .nest-menu .el-submenu > .el-submenu__title:hover,
.el-menu--vertical .el-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.06) !important; }

.el-menu--vertical > .el-menu--popup {
  max-height: 100vh;
  overflow-y: auto; }
  .el-menu--vertical > .el-menu--popup::-webkit-scrollbar-track-piece {
    background: #d3dce6; }
  .el-menu--vertical > .el-menu--popup::-webkit-scrollbar {
    width: 6px; }
  .el-menu--vertical > .el-menu--popup::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #99a9bf; }
